<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">View Promotion</span>
                    <span class="pull-right" style="cursor:pointer;" @click="CancelPromotions()">
                        <i class="icon icon-close" style="font-size: 22px!important; color: #5d5b5b;"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-0">
                    <div class="v-modal-layout pr-4" style="max-height: 430px;overflow-y: auto;">
                        <div class="row justify-content-end mr-4 mb-2" v-if="!ajaxCall">
                            <button class="btn btn-secondary btn-smm" @click="editPromotion(details._id)">
                                <i class="icon icon-pencil fs-14"></i>
                                Edit
                            </button>
                        </div>
                        <div class="border-bg">
                            <p class="text-secondary font-600 pl-4">
                                General Section :
                            </p>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Promotion for
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.promotion_for">
                                        <span class="a-q-Jc-wg-contactView">
                                            <span v-if="details.promotion_for.take_away">Take- Away,</span>
                                            <span v-if="details.promotion_for.dine_in">Dine In,</span>
                                            <span v-if="details.promotion_for.deliveries">Deliveries,</span>
                                            <span v-if="details.promotion_for.online">Online</span>
                                            <span v-if="details.promotion_for.online">|<span v-for="(co,coi) in details.company_online" :key="coi"> {{co.name}}<span v-if="coi != details.company_online.length - 1">,</span></span></span>
                                            <span v-if="details.promotion_for.deliveries">|<span v-for="(cd,cdi) in details.company_deliveries" :key="cdi"> {{cd.name}}<span v-if="cdi != details.company_deliveries.length - 1">,</span></span></span>
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Promotion Type
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.promotion_type}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Promotion Name
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.name}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Promotion Code
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView pt-2 pb-2" v-if="details.code">
                                        <span class="badgenew bg-greennew">
                                            <span>
                                                {{details.code}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Outlet ID
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView pt-2 pb-2" v-if="details.outlet_id">
                                        <span class="badgenew bg-bluenew mr-2" v-for="outlet in details.outlet_id" :key="outlet._id">
                                            <span>
                                                {{outlet.name}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Description
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.description}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView" v-if="details && details.flat_details">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Inventory Type
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span v-if="details.flat_details.applicable_for =='category'">Category</span>
                                        <span v-if="details.flat_details.applicable_for =='sub_category'">Sub Category</span>
                                        <span v-if="details.flat_details.applicable_for =='product_type'">Product Type</span>
                                        <span v-if="details.flat_details.applicable_for =='products'">Products</span>
                                    </div>
                                </div>
                            </div>  
                            <div class="a-q-Jc-j-contactView" v-if="details.flat_details && details.flat_details.flat_value">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        <span v-if="details.flat_details.flat_type == 'percentage'">Percentage</span> 
                                        <span v-else>Amount</span> 
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            <span v-if="details.flat_details.flat_type == 'amount'">{{details.outlet_currency.symbol}}</span>
                                            {{details.flat_details.flat_value}} 
                                            <span v-if="details.flat_details.flat_type == 'percentage'">%</span> 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView" v-if="details.flat_details && details.flat_details.max_limit_amount">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Max Limit of Amount 
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}{{details.flat_details.max_limit_amount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView" v-if="details && details.promotion_details && details.promotion_details.buy_details">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Inventory Type For Buy X
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span v-if="details.promotion_details.buy_details.applicable_for =='category'">Category</span>
                                        <span v-if="details.promotion_details.buy_details.applicable_for =='sub_category'">Sub Category</span>
                                        <span v-if="details.promotion_details.buy_details.applicable_for =='product_type'">Product Type</span>
                                        <span v-if="details.promotion_details.buy_details.applicable_for =='products'">Products</span>
                                    </div>
                                </div>
                            </div>  
                            <div class="a-q-Jc-j-contactView" v-if="details && details.promotion_details && details.promotion_details.buy_details">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        <span v-if="details.promotion_details.buy_details.buy_type == 'quantity'">Quantity</span>
                                        <span v-else-if="details.promotion_details.buy_details.buy_type == 'percentage'">Percentage</span>
                                        <span v-else>Amount</span>
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            <span v-if="details.promotion_details.buy_details.buy_type == 'amount'">{{details.outlet_currency.symbol}}</span>
                                            {{details.promotion_details.buy_details.buy_value}}
                                            <span v-if="details.promotion_details.buy_details.buy_type == 'percentage'">%</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView" v-if="details && details.promotion_details && details.promotion_details.get_details">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Inventory Type For Get Y
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span v-if="details.promotion_details.get_details.applicable_for =='category'">Category</span>
                                        <span v-if="details.promotion_details.get_details.applicable_for =='sub_category'">Sub Category</span>
                                        <span v-if="details.promotion_details.get_details.applicable_for =='product_type'">Product Type</span>
                                        <span v-if="details.promotion_details.get_details.applicable_for =='products'">Products</span>
                                    </div>
                                </div>
                            </div>  
                            <div class="a-q-Jc-j-contactView" v-if="details && details.promotion_details && details.promotion_details.get_details">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        <span v-if="details.promotion_details.get_details.get_type == 'quantity'">Quantity</span>
                                        <span v-else-if="details.promotion_details.get_details.get_type == 'percentage'">Percentage</span>
                                        <span v-else>Amount</span>
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            <span v-if="details.promotion_details.get_details.get_type == 'amount'">{{details.outlet_currency.symbol}}</span>
                                            {{details.promotion_details.get_details.get_value}}
                                            <span v-if="details.promotion_details.get_details.get_type == 'percentage'">%</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView" v-if="details && details.promotion_details && details.promotion_details.get_details && details.promotion_details.get_details.max_limit_amount">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Max Limit of Amount 
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}
                                            {{details.promotion_details.get_details.max_limit_amount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-bg">
                            <p class="text-secondary font-600 pl-4">
                                Date Section :
                            </p>
                            <div class="back-blue p-3 ml-3 mr-3 ">
                                <div class="row no-gutters borderBottom pb-2">
                                    <span class="text-white col-md-6">All Day</span>
                                    <span class="text-white" style="text-transform:capitalize !important;" v-if="details && details.validity">{{details.validity.all_day}}</span>
                                </div>
                                <div class="row no-gutters borderBottom pb-2 pt-1" v-if="details && details.validity">
                                    <span class="text-white col-md-6">Validity Start Date<span v-if="details && !details.validity.all_day">/ Time</span></span>
                                    <span class="text-white" style="text-transform:capitalize !important;">{{details.validity.validity_start_date | moment("DD/MM/YYYY")}}
                                        <span v-if="details && !details.validity.all_day">,{{details.validity.validity_start_time}}</span>
                                    </span>
                                </div>
                                <div class="row no-gutters pb-2 pt-1" v-if="details && details.validity">
                                    <span class="text-white col-md-6">Validity End Date<span v-if="details && !details.validity.all_day">/ Time</span></span>
                                    <span class="text-white" style="text-transform:capitalize !important;">{{details.validity.validity_end_date | moment("DD/MM/YYYY")}}
                                        <span v-if="details && !details.validity.all_day">,{{details.validity.validity_end_time}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="border-bg">
                            <p class="text-secondary font-600 pl-4">
                                Repeat Section :
                            </p>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Repeat Section
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.validity">
                                        <span class="a-q-Jc-wg-contactView" style="text-transform:capitalize;">
                                            {{details.validity.repeat}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView" v-if="details.validity && details.validity.repeat_end_date">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Select End Date
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.validity">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.validity.repeat_end_date | moment("DD/MM/YYYY")}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-bg">
                            <p class="text-secondary font-600 pl-4">
                                Customer Limits Section :
                            </p>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Daily Value Limit
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}
                                            {{details.limits.customer.daily.value}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Daily Usage Count
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.limits.customer.daily.usage_count}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Weekly Value Limit
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}
                                            {{details.limits.customer.weekly.value}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Weekly Usage Count
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.limits.customer.weekly.usage_count}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Monthly Value Limit
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}
                                            {{details.limits.customer.monthly.value}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Monthly Usage Count
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.limits.customer.monthly.usage_count}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Yearly Value Limit
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}
                                            {{details.limits.customer.yearly.value}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Yearly Usage Count
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.limits.customer.yearly.usage_count}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-bg">
                            <p class="text-secondary font-600 pl-4">
                                Outlet Limits Section :
                            </p>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Daily Value Limit
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}
                                            {{details.limits.outlet.daily.value}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Daily Usage Count
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.limits.outlet.daily.usage_count}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Weekly Value Limit
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}
                                            {{details.limits.outlet.weekly.value}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Weekly Usage Count
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.limits.outlet.weekly.usage_count}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Monthly Value Limit
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}
                                            {{details.limits.outlet.monthly.value}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Monthly Usage Count
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.limits.outlet.monthly.usage_count}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Yearly Value Limit
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.outlet_currency.symbol}}
                                            {{details.limits.outlet.yearly.value}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Yearly Usage Count
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.limits">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.limits.outlet.yearly.usage_count}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-bg" v-if="details && details.account_code">
                            <p class="text-secondary font-600 pl-4">
                                Account Code :
                            </p>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Account Code
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{details.account_code}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-bg">
                            <p class="text-secondary font-600 pl-4 pt-4">
                                User Section :
                            </p>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Assign Promotion to
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details && details.customer_list && details.customer_list.length" >
                                        <span class="a-q-Jc-wg-contactView" v-if="details && details.customer_list.length">
                                            Customers - <span class="font-500" v-for="(cust,i) in details.customer_list" :key="i">{{cust.name}}
                                                <span v-if="i != details.customer_list.length - 1">,</span>
                                            </span>
                                        </span><br>
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="details.customer_list.length == 0">
                                        <span class="a-q-Jc-wg-contactView">-</span>
                                    </div>
                                </div>
                            </div>
                        </div>                              
                    </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                    <a class="btn btn-secondary btn-smm text-uppercase"
                    @click="CancelPromotions">CLOSE</a
                    >
                </div>
            </div>
        </modal>
        <promotion-edit modal_name="edit-promotion" :source="'fromView'" @formviewCancel="formviewCancel()"></promotion-edit>
    </div>
</template>
<script>
const PromotionEdit = () => import('./EditPromotion')
import { EventBus } from '../eventBus/nav-bar-event'
export default {
    data(){
        return{
            ajaxCall:false,
            details:{
                customer_list:[],
            },
            source:''
        }
    },
    components:{
        PromotionEdit
    },
    props: ['modal_name'],
    methods: {
        CancelPromotions() {
            this.details = {
                customer_list:[],
            }
            this.$modal.hide(this.modal_name)
        },
        editPromotion(id){
            setTimeout(() => {
                this.$modal.hide(this.modal_name)
                EventBus.$emit('pViewEdit',id)
                this.$modal.show('edit-promotion')  
            }, 200);
        },
        formviewCancel(){
           this.$modal.show(this.modal_name)
           this.getDetails() 
        },
        async getDetails(){
            try {
                this.ajaxCall = true
                let params = {
                    _id:this.details._id,
                    required_currency:true
                }
                let res = await this.$http.post('promotions/get_promotion_by_id',params)
                if(res.data.status_id == 1){
                    this.ajaxCall = false
                    this.details = res.data.promotion
                }else{
                    this.$swal({
                        title: res.data.error,
                        text: '',
                        type: 'warning'
                    })
                    this.CancelPromotions()
                }
            } catch (reason) {
                
            }
        }
    },
    mounted(){
        EventBus.$on('pView',id=>{ 
            this.ajaxCall = true
            this.$http.post('promotions/get_promotion_by_id',{_id:id,required_currency:true})
            .then(res=>{
                if(res.data.status_id == 1){
                    this.ajaxCall = false
                    this.details = res.data.promotion
                }else{
                    this.$swal({
                        title: res.data.error,
                        text: '',
                        type: 'warning'
                    })
                    this.CancelPromotions()
                }
            })
        })
    }
}
</script>
<style scoped>
.a-q-Jc-Pj-Ya-contactView {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    line-height: 30px;
}

.a-q-Jc-Pj-Ya-contactView {
    line-height: 25px;
    padding: 16px 10px 16px 3px;
    border-bottom: solid 1px #9e9e9e69;
}

.a-q-Jc-bj-contactView {
    -webkit-flex: 0 0 190px;
    flex: 0 0 190px;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303031;
    margin-left: 13px;
}

.a-q-Jc-Lb-contactView {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-decoration: none;
    line-height: 1.21;
    letter-spacing: normal;
    color: #303031;
    margin-left: 88px;
}

.a-q-Jc-Pj-Ya-contactView:nth-of-type(even) {
    background-color: #f5f6f9;
}

.a-q-Jc-Lb-contactView.linkactive {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: right;
    color: #00448b;
}

.a-q-Jc-wg-contactView {
    text-decoration: none;
}
.badgenew{
    padding: 5px 10px !important;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 8px;
    color: #fff !important;
}
.bg-greennew{
    background-color: #4CAF50 !important;
}
.bg-bluenew{
    background-color: #00448b !important;
}
.border-bg{
    background-color: #fff !important;
    padding: 8px !important;
    border-radius: 12px !important;
    margin-bottom: 8px !important;
}
.back-blue{
    background-color: #00448b !important;
    border-radius: 12px !important;
}
.borderBottom{
    border-bottom: 1px solid #fff;
}
.font-500{
    font-weight: 500 !important;
}
</style>
